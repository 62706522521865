<script lang="ts" setup>
import { useModalStore } from '~/store/modals';

const modalStore = useModalStore();
const globalModal: any = computed(() => modalStore.globalModal);

const close = () => {
  globalModal.value.cancelCallback && globalModal.value?.cancelCallback();
};

const ok = () => {
  globalModal.value.okCallback && globalModal.value?.okCallback();
};
</script>

<template>
  <v-dialog
    v-model="globalModal.show"
    width="534"
    class="pop-up-dialog"
    :persistent="true"
    data-testid="common-custom-global-dialog"
  >
    <v-card>
      <div
        v-if="globalModal.icon"
        class="confirmation-dialog__header d-flex align-center py-8 px-10"
      >
        <component
          :is="globalModal.icon"
          v-if="globalModal.icon"
          class="mx-5 button-v2-image dialog-logo"
        />

        <div
          v-if="globalModal.title"
          class="mt-4 text-primary-1 text-desktop-body-large"
        >
          {{ globalModal.title }}
        </div>
      </div>

      <div class="confirmation-dialog__body py-8 px-10 bg-gray-5 text-desktop-body-large text-black-2">
        <p
          class=" confirmation-dialog__body-description"
          :class="[globalModal.cssClass]"
        >
          {{ globalModal.message }}
        </p>

        <div
          v-if="globalModal.okText || globalModal.cancelText"
          class="dialog-actions mt-9 d-flex"
        >
          <v-btn
            v-if="globalModal.cancelText"
            variant="text"
            class="bg-gray-6 text-black-2 text-desktop-body-large"
            @click="close()"
          >
            {{ globalModal.cancelText }}
          </v-btn>
          <v-btn
            v-if="globalModal.okText"
            variant="text"
            class="bg-primary-1 text-gray-6 text-desktop-body-large"
            @click="ok()"
          >
            {{ globalModal.okText }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">

</style>
