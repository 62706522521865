<script setup lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/nuxt';
import { useUserStore } from '~/store/user';
import AppBar from '~/components/layout/AppBar.vue';
import SideNav from '~/components/layout/SideNav.vue';
import GlobalModal from '~/components/common/GlobalModal.vue';
import { useUtilsStore } from '~/store/utils';

const userStore = useUserStore();
const orgStore = useOrgStore();
const utilStore = useUtilsStore();
userStore.fetchUser();
</script>

<template>
  <div>
    <v-card variant="flat">
      <SpeedInsights :sample-rate="0.5" />

      <v-layout>
        <SideNav
          v-if="utilStore.showSideNav"
          :user-name="userStore.name"
        />
        <AppBar v-if="utilStore.showSideNav" />
        <v-main v-if="userStore.name && orgStore.activeOrgId">
          <div class="pa-4 bg-gray-5">
            <NuxtPage />
          </div>
        </v-main>
        <GlobalModal />
      </v-layout>
    </v-card>
  </div>
</template>
